import React from 'react'
import { FaTimes } from 'react-icons/fa';
import * as vidStyles from './video.module.css'

const Video = ({modal, setModal, id}) => {
    return (
        <section className={vidStyles.modal__bg}>
            <div className={vidStyles.modal__align}>
                <div className={vidStyles.modal__content}>
                    <FaTimes
                        className={vidStyles.modal__close}
                        arial-label='Close modal'
                        onClick={setModal}
                    />
                    <div className={vidStyles.modal__videoalign}>
                        <iframe
                            className={vidStyles.modal__videostyle}
                            loading='lazy'
                            width='896'
                            height='504'
                            src={id}
                            title='YouTube video player'
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Video