import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PortfolioItem from './PortfolioItem'
import * as gridStyles from './portfoliogrid.module.css'

const PortfolioGrid = () => {
    const data = useStaticQuery(query)
    const videos = data.allContentfulPortfolio.nodes
    return (
        <div className={gridStyles.gridcontainer}>
            {videos.map(video => <PortfolioItem video={video} key={video.id}/>)}
        </div>
    )
}

export const query = graphql`
  {
    allContentfulPortfolio {
      nodes {
        id
        title
        description
        video
        alt
        thumb {
          gatsbyImageData(placeholder: BLURRED)
        }
        category
      }
    }
  }
`

export default PortfolioGrid;