import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import * as portStyles from './portfolio.module.css'
import PortfolioGrid from '../components/PortfolioGrid'
import SEO from '../components/SEO'

const Portfolio = () => {
    return (
        <Layout>
            <SEO title='Portfolio | Poolusfilm' description='Meie Tööd'/>
            <Hero name='PORTFOLIO' imgurl='portfolioHero' imgpos='0px -300px' />
            <main className={portStyles.page}>
                <PortfolioGrid />
            </main>
        </Layout>
    )
}

export default Portfolio