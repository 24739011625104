import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaPlayCircle } from 'react-icons/fa'
import Video from './Video'
import * as portStyles from './portfolioitem.module.css'

const PortfolioItem = ({ video }) => {
    const [modal, setModal] = useState(false);

    const openModal = () => {
        setModal(!modal);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setModal(!modal);
        }
    }

    const thumbNail = getImage(video.thumb);

    return (
        <div className={portStyles.griditem}>
            <div role='button' tabIndex={0} onClick={openModal} onKeyDown={handleKeyPress} className={portStyles.thumb} >
                {modal ? <Video setModal={setModal} id={video.video} /> : undefined}
                <GatsbyImage image={thumbNail} alt={video.alt} />
                <div className={portStyles.imgoverlay}>
                    <FaPlayCircle className={portStyles.mediaicon} />
                </div>
            </div>
            <div className={portStyles.content}>
                <h5>{video.title}</h5>
                <p>{video.description}</p>
            </div>
        </div>
    )
}

export default PortfolioItem;